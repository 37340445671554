
.Product_card {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    opacity: 0;
    max-width: 1300px;
    animation: fadeIn 0.4s ease-in-out forwards;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.product_left_items {
    overflow: hidden;
    height: 565px;
    width: 375px;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 50px;
    position: relative;
    box-sizing: border-box;
    z-index: 1000000000;

}



.product_card_image {
    box-sizing: border-box;
    overflow: hidden;
    height: 355px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 0;
}

.product_card_image_container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    height: 80px;
    padding: 10px;
    display: flex;
    justify-content:center ;
    align-items: center;

}


.product_card_image img {

    height: 100%;

}
.thumbnail{
    cursor: pointer;
    margin: 5px;

}

.product_card_text {
    min-height: 410px !important;
    box-sizing: border-box;
    margin: 30px;

    padding-left: 5px;
    padding-right: 5px;
}

@media (max-width: 800px) {


    .product_left_items {

        margin-left: auto !important;
        margin-right: auto !important;

    }

    .product_card_text {
        box-sizing: border-box;
        margin: 0 auto !important;
    }
}

.product_card_name {
    min-height: 35px;
    color: var(--main-color-1) !important;
    display: flex;
    flex-wrap: wrap;
    font-size: 25px;
    align-items: center;
    font-family: A, sans-serif;
}

.product_card_brand {
    color: var(--main-color-1) !important;
    margin-right: 10px;
    font-size: 20px;
}

.product_card_description {
    margin-top: 30px;
    display: flex;

    min-height: 250px;
    flex-wrap: wrap;
    flex-direction: column !important;
    color: var(--main-color-1) !important;
    font-size: 14px;
    font-family: A, sans-serif;

}


.desc_itm {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.first_desc {
    font-weight: 700;
    margin-right: 5px;
}

.product_card_id {
    min-height: 22px;
    color: var(--main-color-1) !important;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 15px;
    font-family: A, sans-serif;
}

.lari {
    font-family: A, sans-serif !important;
    margin-left: 5px;
    font-size: 20px;

}


.product_card_icons .icon.active {
    background-color: #82cb19 !important;


}

.product_card_icons .icon.active .icon_basket_text {
    display: none;
}

.product_card_icons .icon.active svg {
    margin-right: 0;
}


.product_card_id svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.product_description {
    color: var(--main-color-1) !important;
    font-family: A, sans-serif;
    font-size: 15px;
    padding: 8px;
    border-radius: 10px;
    margin-top: 5px;


}


.product_sizes {
    box-sizing: border-box;
    display: flex;
    margin-top: 20px;
    padding: 8px;
    border-radius: 10px;
    background-color: var(--main-color-3) !important;
    align-items: center;
    width: 100%;
    height: 50px;
}

.product_sizes_item {

    display: flex;
    align-items: center;
    font-family: A, sans-serif;
    width: auto;
    margin-right: 10px;
    height: 30px;
    font-size: 20px;
    font-weight: 700;

}

.product_sizes_text {
    font-weight: normal !important;
    font-family: A, sans-serif;
    font-size: 14px;
}

.product_card_description_container {
    width: auto;

    height: 430px;
    min-height: 375px;
    overflow: hidden;
    position: relative;
}

.show_more {
    cursor: pointer;
    position: absolute;
    z-index: 100000000;
    bottom: 0;
    width: 100%;
    height: 170px;
    display: flex;
    align-items: end;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(237, 28, 36, 0) 0%, var(--main-color-2) 70%);

}


.show_more.active {
    display: none;
}

.product_card_description_container.active {
    height: 100% !important;
}

.show_more.active button {
    display: none;
}


.show_more button {
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: none;
    font-family: A, sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 10px;
    width: 160px;
    height: 58px;
    border-radius: 10px;
    color: var(--main-color-2) !important;
    background-color: var(--main-color-4) !important;
}


.product_description strong {
    margin-left: 10px;
}


.product_description:nth-child(even) {
    background-color: var(--main-color-3) !important;
}


.product_card_price {
    margin-left: 20px;
    width: 100%;
    min-height: 42px;
    display: flex;
    align-items: end;
    font-size: 30px;
    font-family: D, sans-serif;
    color: var(--main-color-5) !important;
    font-weight: 700;
}


.product_price_container {
    align-items: center;
    box-sizing: border-box;
    bottom: 6px;
    position: absolute;
    right: auto;
    left: auto;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 20px 10px 20px 10px;
    border-radius: 15px;
    background-color: var(--main-color-3) !important;
    flex-direction: column;
}

.product_card_pricew {
    align-items: end;
    display: flex;
    font-size: 30px;
    font-family: D, sans-serif;
    font-weight: 700;
    text-decoration: line-through !important;
    color: var(--main-color-1) !important;
}

.product_card_pricew .lari {
    font-family: A, sans-serif !important;
    text-decoration: none;
}

.product_card_pricew {
    margin-left: 25px;
}


.product_card_icons {
    width: 100%;

    height: 70px;
    cursor: pointer;
    font-family: A, sans-serif;
    font-size: 14px;
    color: var(--main-color-2) !important;
    margin-top: 20px;
    display: flex;

}

.product_card_icons .icon {
    height: 50px;
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color-5) !important;
    width: auto;
    padding: 15px;
    border-radius: 10px;
}

.product_card_icons .icons {
    margin: 5px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color-5) !important;
    width: auto;
    padding: 17px;
    border-radius: 10px;
}

.product_card_icons .icon svg {
    margin-right: 5px;
}