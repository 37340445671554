
.Menu {
    margin-top: 20px;
    width: 100%;
    height: 100%;

}


.Menu_items {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;

    margin: 0 auto;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1070px;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Menu_container {
    box-sizing: border-box;
    display: none;
    max-width: 1070px;
    margin: 0 auto;
    text-align: center;
    width: 100%;

    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Main_menu_item.active + .Menu_container {
    display: flex;
}

.Menu_item {
    outline: none !important;
    text-decoration: none !important;
    text-underline: none !important;
    background-color: var(--main-color-3) !important;
    padding: 5px;
    cursor: pointer;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 3px 8px;
    font-family: A, sans-serif;
    font-size: 10px;

    width: 100px;
    height: 125px;
    margin: 1%;
    display: inline-block;
}


.Menu h2 {
    float: left;
    margin-right: auto;
    font-family: A, sans-serif;
    font-size: 15px;
    margin-left: 10px;
}

.Menu_item:hover {

    background-blend-mode: multiply;

    background-position: center;
    background-size: cover;

}

.Menu_item_text {
    outline: none !important;
    text-decoration: none !important;
    text-underline: none !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--main-color-1) !important;
    padding: 5px;

    width: auto;
    margin-left: 5px;
    margin-right: 5px;
    height: 45px;
    border-radius: 10px;
    box-sizing: border-box;
    text-overflow: ellipsis;
}

.Menu_img {

    width: 100px;
    height: auto;
}

.Search_bar {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1070px;
    display: flex;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}

.search {
    max-width: 1300px;
    box-sizing: border-box;
    background-color: var(--main-color-3) !important;
    border: 2.5px solid transparent;
    border-radius: 15px;
    color: #707d7d;
    display: inline-block;
    font-family: A, sans-serif;
    font-size: 15px;
    height: 50px;
    margin: 0 auto;
    outline: none;
    text-indent: 45px;

    width: 100% !important;
}

.searchico {
    color: var(--main-color-5) !important;
    display: inline-block;
    margin-left: 18px;
    position: absolute;
}


.Main_menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1300px;
    margin: 0 auto;
}

.Main_menu_item {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    width: 100%;
    position: relative;
    max-width: 370px;
    margin: 5px;
    height: 130px;
    align-items: center;
    display: flex;

}

.Main_menu_img {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                           not supported by any browser */
    height: 110px !important;


}


.Main_menu_text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                           not supported by any browser */
    margin-left: 5px;
    color: var(--main-color-1) !important;
    font-family: A, sans-serif;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 45px;
    font-weight: 700;
    font-size: 13px;

}

.Main_menu_description {
    margin-top: 7px;
    max-width: 230px;

    font-weight: normal !important;
    font-size: 10px;
}

.line {

    position: absolute;
    z-index: -10000;
    width: 100%;
    height: 75px;
    border-radius: 15px;
    left: 0;
    top: 30px;
    background-color: var(--main-color-3) !important;
}


.Main_menu_item.active .icon svg {
    rotate: 180deg;
    color: var(--main-color-5) !important;
}

.Main_menu_item .icon {

    right: 0;
    padding-right: 5px;
    display: flex;
    float: right;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 26px;
    margin: 0 auto;
    height: 75px;
    color: var(--main-color-6) !important;
}

.Main_menu_item .icon svg {

    width: 20px;
    height: 20px;

}