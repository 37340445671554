
.Bar {
    width: 100%;
    height: 100%;
}

.Bar_container1 {
    box-sizing: border-box;
    background-color: var(--main-color-4) !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.IconsR {
    justify-content: space-between;
    display: flex;
    width: auto;
    height: 50px;
    align-items: center;
    float: right;
    margin-left: auto;
}


.Bar_container2 {
    box-sizing: border-box;
    background-color: var(--main-color-2) !important;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-left: 25px;
    padding-right: 25px;
}

.bar_item {
    cursor: pointer;
    color: white;
    margin-right: 15px;
    display: flex;

    align-items: center;
    font-family: A, sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.bar_ico {

    width: 20px !important;
    height: 20px !important;
    stroke: var(--main-color-5) !important;
    background-color: var(--main-color-3) !important;
    margin-right: 10px;
    padding: 5px;
    border-radius: 10px;
}


.MR {
    margin-left: auto;
    float: right;

}

.BarL {
    font-size: 12px;
}

.Logo {
    height: 60px;
}


.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 65px;
}

.menu ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 20px;
    height: 65px;
    width: 100%;
}

.menu ul li a{
    cursor: pointer;
    color: var(--main-color-1) !important;
    font-family: A, sans-serif;
    margin-left: 15px;
    margin-right: 15px;
    text-decoration: none;
    list-style: none;
    height: auto;
    transition: .3s linear;
    border-bottom: 2px solid var(--main-color-2) !important;

}

.menu ul li:hover {

    transition: .3s linear;
}

.advert {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background: linear-gradient(180deg, var(--main-color-4) 0%, var(--main-color-4) 100%);
}

.advert_img {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    max-width: 935px;
    max-height: 150px;
}

.advert_X {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    right: 5px;
    margin-top: 5px;
}

.advert_X_icon {
    stroke: var(--main-color-0) !important;
}

.advert.active {
    display: none !important;
}

.user {
    cursor: pointer;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 5px;
    background-color: var(--main-color-3) !important;
}

.user .icon {
    stroke: var(--main-color-1) !important;
}

.Panel_continaer {
    width: 100%;
    height: 80%;
    margin: 30px;
}

.Panel_continaer .bar_ico {
    stroke: var(--main-color-1) !important;
}

.Panel_continaer .bar_item {
    margin-top: 20px;
    color: var(--main-color-1) !important;
}

.Panel_continaer .menu,.Panel_continaer .menu ul {
    width: 100%;
    height: 100%;
    text-align: left!important;
    display: block;
    flex-direction: column !important;
}

.Panel ul{
    margin: 20px auto;
    padding: 0;
    text-align: left!important;
}
.Panel li{
    margin: 20px auto!important;
    padding: 0;
    font-size: 20px;
    font-family: A,sans-serif;
    text-align: left!important;
}
