
.Burger {
    width: 35px;

    z-index: 100000000000000000000000000000000000!important;
    height: 35px;
    position: relative;


}

.burgerICO {
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    float: right;
    transition: all 0.4s ease-out;
    display: inline-flex;
    cursor: pointer;
    width: 35px;

    height: 35px;
    text-align: center;
    align-items: center;
    border-radius: 5px;
}

.ham {

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}


.line {
    pointer-events: none;
    fill: none;
    transition: 0.5s, stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: var(--main-color-1) !important;
    stroke-width: 7;
    stroke-linecap: round;
}

.ham1.active .line {
    stroke: var(--main-color-1) !important;

}

.ham1 .line {
    stroke: var(--main-color-1) !important;

}

.ham:hover .line {
    stroke: var(--main-color-1) !important;
}


/* */
.ham1 .top {
    stroke-dasharray: 40 134;
    stroke-dashoffset: 12px;
}

/* */
.ham1 .bottom {
    stroke-dasharray: 40 180;
    stroke-dashoffset: 20px;
}

/* */
.ham1.active .top {
    stroke-dashoffset: -98px;
}

/* */
.ham1.active .bottom {
    stroke-dashoffset: -138px;

}

.hide {
    display: none;
}

.Burger.active html {
    display: flex;
}


.Panel {
    padding: 0 !important;
    margin: 0 auto !important;
    text-align: center !important;
    transition-duration: 0.5s;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;

    z-index: 99999;
    background-color: var(--main-color-2);
    justify-content: center;
    justify-self: center;
    justify-items: center;
    align-content: center;
    align-self: center;
    align-items: center;
    vertical-align: center;

}

.Panel ul {
    width: 100%;
    height: auto;
    text-align: center !important;

}


.Panel li:hover {
    color: var(--main-color-1);
}


.swicher {
    margin-left: auto;
}


.checkbox {

    opacity: 0;
    position: absolute;
}

.checkbox-label {
    background-color: var(--main-color-3) !important;
    width: 42px;
    height: 20px;
    border-radius: 50px;
    position: relative;
    padding: 3px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fa-moon, .fa-sun {
    width: 20px;
    height: 20px;
}

.fa-moon {
    color: var(--main-color-1) !important;
}

.fa-sun {
    color: var(--main-color-2) !important;
}

.checkbox-label .ball {
    /*
    box-shadow: inset 0 0 0 8px var(--main-color-2);

    */

    background-color: var(--main-color-2) !important;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    transition: transform 0.2s linear;
}


.checkbox:checked + .checkbox-label .ball {
    transform: translateX(21px);
}

.flag {
    width: 26px;
    height: 26px;
    position: absolute;
    margin-left: 10px;
    background-color: var(--main-color-3) !important;
    pointer-events: none
}

.lang {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}


.lang select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 15px;
    font-family: A,sans-serif;
    font-weight: 500;
    display: flex;
    text-align: center;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 5px;
    width: 40px;
    padding-left:10px;
    padding-right: 10px;
    height: 26px;
    color: var(--main-color-1) !important;
    background-color: var(--main-color-3) !important;
    border-style: none;
}



