.row {
    display: flex;
    flex-wrap: wrap;
}

ul {
    list-style: none;
}

.footer_container {
    font-family: A, sans-serif;
    max-width: 1170px;
    margin: auto;

}

.footer_logo {

    fill: var(--main-color-1) !important;
    width: 120px;
    height: auto;
}

.footer {
    background-color: var(--main-color-2) !important;
    padding: 70px 15px;
    margin: 15px auto;
}

.fotera {
    background-color: var(--main-color-1) !important;
    color: var(--main-color-2) !important;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    font-family: A, sans-serif;
}

.footer-col {
    margin: 0 auto;
    width: auto;
    padding: 0 15px;
}

.footer-col h4 {
    font-size: 18px;
    color: var(--main-color-1) !important;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
    margin-top: 0;
}

.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: var(--main-color-1) !important;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;

    text-decoration: none;
    font-weight: 300;
    color: var(--main-color-1) !important;
    display: block;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.footer-col ul {
    padding-left: 0 !important;
}


.soc_ico {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-col .social-links a {

    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: var(--main-color-1) !important;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: var(--main-color-2) !important;
    transition: all 0.5s ease;
}

.footer-col .social-links a svg {
    text-align: center;
    color: var(--main-color-2) !important;
    margin: -15px !important;
}

.footer-col .social-links a:hover svg {
    color: var(--main-color-4) !important;
}

.footer-col .social-links a:hover {
    color: #414141;
    background-color: var(--main-color-2) !important;
}

/*responsive*/
@media (max-width: 767px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media (max-width: 1030px) {
    .BarL {
        display: none;
    }
}

@media (max-width: 1030px) {

    .BarL {
        display: none;
    }

}


@media (min-width: 1090px) {
    .BURGER_M {
        display: none;
    }

}


@media (max-width: 1090px) {
    .menu {
        display: none;
    }
}

@media (max-width: 574px) {
    .Bar_container1 {
        display: none;
    }

    .bar_item {

        margin-right: auto;
    }

    .footer-col {
        width: 100%;
    }


}



