
.xbar {
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    overflow: hidden !important;
    background-color: var(--main-color-2) !important;
    outline: none !important;
    border-style: none !important;
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
}

@media (min-width: 1000px) {
.xbar{
    display: none!important;
}
}


.Linkus{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.xbar_container {


    overflow: hidden !important;
    width: 100%;
    height: 70px;
    padding: 15px!important;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.xbar_items {
    display: flex;
    align-items: center;
    justify-content: center;
}
.blade{
    opacity: 60%;

}
.xbar_item {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 13px;
    margin-right: 13px;
}

.xbar_item .icon {
    outline: none!important;
    border-style: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    stroke: var(--main-color-1) !important;
}

rect{
    outline: none!important;
    border-style: none!important;
    opacity: 0;
}
.xbar_item.active {
    border-radius: 15px;
/*    background-image: url("../img/a.gif");
    background-position: center;
    background-size: contain;
    background-blend-mode: lighten;
    filter: grayscale(100%);*/



    background-color: var(--main-color-5) !important;
}


.xbar_item.active .icon {
    stroke: var(--main-color-2) !important;
}

.circle {
    position: absolute;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    margin-top: -17px;
    margin-left: 14px;
    background-color: var(--main-color-6) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--main-color-2) !important;
    font-family: "fontB", sans-serif;
    font-size: 9px !important;
}

.circle.active {
    display: none;
}

.SMS_ICO {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


