body {
  margin: 0;
  background-color:  var(--main-color-2) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  height: 100%;


}
html{
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
*{
 outline: none;
}
.container{
  width: 100%;
  height: 100%;
  min-height: 100vh;

}
.qwe_style{
  filter: grayscale(100%) !important;
  opacity: 20%;
  pointer-events: none!important;
}
.qwe_text{
  font-family: "fontB", sans-serif;
  color: #ED1C24;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  outline: none!important;
  text-decoration: none!important;
  text-underline: none!important;
}

@font-face {
  font-family: 'A';
  src: local('A'), url(../../../GNG/web/src/fonts/1.ttf) format('truetype');
}

@font-face {
  font-family: 'B';
  src: local('B'), url(../fonts/2.ttf) format('truetype');
}

@font-face {
  font-family: 'C';
  src: local('C'), url(../fonts/3.ttf) format('truetype');
}

@font-face {
  font-family: 'D';
  src: local('D'), url(../fonts/4.ttf) format('truetype');
}

@font-face {
  font-family: 'E';
  src: local('E'), url(../fonts/5.ttf) format('truetype');
}

@font-face {
  font-family: 'F';
  src: local('F'), url(../fonts/6.ttf) format('truetype');
}
@font-face {
  font-family: 'G';
  src: local('G'), url(../fonts/7.ttf) format('truetype');
}

:root {
  --main-color-0: #ffffff;
  --main-color-1: #1d1d1b;
  --main-color-2: #ffffff;
  --main-color-3: #f8f8f8;
  --main-color-4: #ED1C24;
  --main-color-5: #ED1C24;
  --main-color-6: #d5d5d5;

  --main-color-7: #ffb700;
  --main-color-8: #7300ff;
}




.header_ef29{
  background-color: #ED1C24!important;
}
.chatCopyright_f49e{
  display: none!important;
}
.wrap_ad00{

}
.global_cb08.__green_e4f5{
  position: fixed!important;
  z-index: 1000000;
  bottom: 60px!important;
}
.iconWrap_dec4{
  background-image: url(../img/msg.svg) !important;
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;

  border-radius: 15px;
}

.logoIconCloud_d06b,.icons_ba4a{
  display: none!important;
}