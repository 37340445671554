
.About {
    width: 100%;
    height: 100%;
}

.about_text {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    font-family: "A", sans-serif;
}

.about_text h1 {
    font-size: 25px;
}

.about_text h3 {

    font-size: 16px;
}

.Services {
    width: 100%;
    height: 100%;
}

.Services_container {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
}

.Services_card {
    margin: 10px;
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 650px;
    box-sizing: border-box;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    overflow: hidden;
}

.Services_img {
    width: 100%;
    box-sizing: border-box;
    height: 250px !important;

    padding: 10px;

}

.si1 {
    background-image: url(../img/1.png);
    background-position: center;
    background-size: cover;
}
.si3 {
    background-image: url(../img/2.png);
    background-position: center;
    background-size: cover;
}
.si2 {
    background-image: url(../img/3.png);
    background-position: center;
    background-size: cover;
}


.Services_description {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.Title {
    font-family: "E", sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.Stitle {

    height: 170px!important;
    font-family: "C", sans-serif;
    font-size: 13px;
    line-height: 20px;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
}
.PTitle{
    width: 100%;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 30px;
}
.Pbutton{
    margin-top: 10px;
    font-family: "E",sans-serif;
    font-size: 23px;
    color: var(--main-color-2);
    padding: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color-5);
    border-radius: 10px;
}