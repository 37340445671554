
.Add{
    background-color: var(--main-color-1) !important;
    padding: 50px;
}

.AddProducts {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--main-color-1) !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    font-family: A,sans-serif;
    max-width: 350px;
}
.progress{

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #1d1d1b;
    z-index: 10000000000000000;
    overflow: hidden;
}
.progress_content {

    background-blend-mode: screen;
    background-position: center;
    width: 300px;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1d1d1b;
    color: var(--main-color-2) !important;
    display: flex;

    align-items: center;
    justify-content: center;
    font-family: "fontB", sans-serif;
    font-size: 50px;
}

.progress_content{

    margin: 0 auto;
}

.AddProducts p{
    display: flex;

    align-items: center;
    box-sizing: border-box;
    max-width: 300px;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    font-size: 13px;
    color: white;
    background-color: #282828;
}

.AddProducts input {
    font-family: A,sans-serif;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    max-width: 300px;
    margin: 5px auto;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    font-size: 13px;
    color: black;
    background-color: white;
    border-style: none;
}

.AddProducts select{
    font-family: A,sans-serif;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    max-width: 300px;
    margin: 5px auto;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    font-size: 13px;
    color: black;
    background-color: white;
    border-style: none;
}


.SELECTS{
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 300px;
    display: flex;
    flex-direction: column;

}
#file{
    font-family: A,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    max-width: 300px;
    margin: 5px auto;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    font-size: 13px;
    color: black;
    background-color: white;
    border-style: none;
}
.Add_btn{
    width: 100%;
    height: 50px;
    border-style: none;
    border-radius: 10px;
    font-family: A,sans-serif;
    margin-top: 30px;
    font-size: 18px;
    background-color: #ffffff;
}

.IMG_input{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 17px!important;
}


