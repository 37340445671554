

.Map{

    width: 100%;
    height: 312px;
    max-width: 1230px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.mapboxgl-ctrl-logo{
    display: none!important;
}

.mapboxgl-ctrl-bottom-right{
    display: none!important;
}

.Map_Page{
    margin-top: 20px;
    width: 100%;
    height: 700px;
    max-width: 1230px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}