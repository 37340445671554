
.Products {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

}

.Products_container {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;


}

@media (min-width: 800px) {
    .filter {
        height: auto!important;

    }
    .filter_btn{
        display: none!important;
    }
}



.Search_barP {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    display: flex;
    margin-top: 20px;
    max-width: 1000px!important;

    margin-bottom: 30px;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}


.searchP {
    max-width: 1300px;
    box-sizing: border-box;
    background-color: var(--main-color-3) !important;
    border: 2.5px solid transparent;
    border-radius: 15px;
    color: #707d7d;
    display: inline-block;
    font-family: A, sans-serif;
    font-size: 15px;
    height: 50px;
    margin: 0 auto;
    outline: none;
    text-indent: 45px;

    width: 100% !important;
}

.searchicoP {
    color: var(--main-color-5) !important;
    display: inline-block;
    margin-left: 18px;
    position: absolute;
}















@media (max-width: 970px) {

    .product_itm{
        width: 350px!important;
        max-width: 350px!important;
    }
}

@media (max-width: 800px) {



    .Products_container{
        flex-direction: column;
    }
    .filter{
        width: 100% !important;
        max-width: 95% !important;
        margin: 0!important;
        align-self: center;

        margin-top: 10px!important;
    }
    .products_list{
        align-self: center!important;
        justify-content: center!important;
        box-sizing: border-box;
        display: flex;
        padding: 15px;
    }

}

.filter.active {
    height: auto!important;
    padding-bottom: 70px;
}
.filter.active .filter_btn .arrow{
    transform-origin: center;
    transform: rotate(180deg) translateX(-10px);

}

.w{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.w img{
    height: 40px;
    padding: 50px;
}

.w img{
    stroke: var(--main-color-3)!important;
}
.filter {
    overflow: hidden;
    height: 50px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: left !important;
    font-family: A, sans-serif;
    box-sizing: border-box;
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    position: relative;
    background-color: var(--main-color-3);
    border-radius: 15px;
    margin-right: 15px;
}

.filter_btn{

    padding: 5px;
    background: linear-gradient(180deg, rgba(237,28,36,0) 0%, var(--main-color-2) 70%);
    cursor: pointer;
    position: absolute;
    margin: 0 auto;
    align-self: center;
    width: 100%;

    box-sizing: border-box;
    height: 50px;
    display: flex;
    justify-content: center;
    bottom: 0;
}
.filter_btn button{
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: none;
    font-family: A, sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 10px;
    width: 300px;
    height: 40px;
    border-radius: 10px;
    color: var(--main-color-2) !important;
    background-color: var(--main-color-5) !important;
}

.filter h2 {
    font-size: 15px;
    margin-top: 0;
    color: var(--main-color-1);
}


.price-input {

    width: 100%;
    display: flex;
    margin: 30px 0 35px;
}

.price-input .field {
    font-family: A, sans-serif;
    display: flex;
    font-size: 13px;
    width: 100%;
    height: 45px;
    align-items: center;
}

.price-input .field span {
    margin-left: 10px;
    color: var(--main-color-1);
}

.field input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 19px;
    margin-left: 12px;
    border-radius: 5px;
    text-align: center;
    border-style: none;
    font-family: A, sans-serif;
    -moz-appearance: textfield;
}


.product_itm {
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.sizes{
    box-sizing: border-box;
    margin-top: 15px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.sizes_continaer{
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.size{
    box-sizing: border-box;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    width: auto;
    height: 50px;

}
.sizes_text{
    color: var(--main-color-1);
    margin-left: 5px;
    margin-bottom: 10px;
    font-size: 15px;
}
.size_input{
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 15px;
    border-radius: 5px;
    padding-left: 5px;
    border-style: none;
    font-family: A, sans-serif;
    -moz-appearance: textfield;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.price-input .separator {
    width: 130px;
    display: flex;
    font-size: 19px;
    align-items: center;
    justify-content: center;
}

.slider {
    z-index: 10;
    box-sizing: border-box;
    height: 5px;
    position: relative;
    overflow: hidden;
    background: var(--main-color-2);
    border-radius: 5px;
}

.slider .progress {

    box-sizing: border-box;
    height: 100%;
    position: absolute;
    left: 0;
    border-radius: 5px;
    background: #ED1C24FF;
}

.range-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-bottom: 20px;
    position: relative;
}

.range-input input {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}


input[type="range"]::-webkit-slider-thumb {
    z-index: 100000!important;
    height: 20px;
    width: 20px;
    background: var(--main-color-5);
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input[type="range"]::-moz-range-thumb {
    height: 20px;

    z-index: 100000!important;
    width: 20px;
    border: none;
    background: var(--main-color-5);
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.range-max::-webkit-slider-thumb{
    z-index: 1000000!important;
    overflow: hidden;

    background-color: var(--main-color-5)!important;
}
.range-max::-moz-range-thumb{
    z-index: 1000000!important;

    background-color: var(--main-color-5)!important;
}


.remove {
    color: var(--main-color-1)!important;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

}

.remove .icon {
    margin-bottom: 5px;
    margin-right: 5px;
}

.products_list {
    margin: 0 auto;
    height: 100%;
    justify-content: center;
    max-width: 900px!important;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

}

.product_itm {

    box-shadow: rgba(215, 215, 215, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    cursor: pointer;
    margin: 5px;
    width: 280px;
    height: 320px;
    overflow: hidden;
    padding: 5px;
    border-radius: 15px;
    background-color: var(--main-color-2);
}

.product_itm_container {
    flex-direction: column;
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;

}


.product_text {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    display: inline-block;
    width: 100%;
    color: var(--main-color-1);
    height: 100%;

}

.product_name{
    margin-top: 5px;
    font-family: "E",sans-serif;
    font-size: 16px;

}
.product_brand{
    display: flex;
    margin-top: 10px;
    font-family: A,sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    align-items: center;
}
.product_brand_model{
    font-family: F,sans-serif;
    color: var(--main-color-2);
    padding: 10px;
    background-color: var(--main-color-5);
    border-radius: 10px;
}

.product_brand_id{
    font-family: E,sans-serif;
    color: var(--main-color-1);
    padding: 10px;
    background-color: var(--main-color-3);
    border-radius: 10px;
    margin-left: 5px;
}


.product_id{
    margin-top: 5px;
    font-family: A,sans-serif;
    font-size: 15px;
}
.product_price{
    margin-top: 10px;
    display: flex;
    box-sizing: border-box;
    font-family: F,sans-serif;
    color: var(--main-color-5);
    font-size: 30px;
    align-items: end;
    font-weight: 700;
}
.product_price .ico{
    margin-top: 5px;
    font-family: A,sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.old_price{
    font-family: A,sans-serif!important;
    display: flex;
    margin-left: 10px;
    font-size: 19px;
    color: #b0b0b0;
    text-decoration: line-through!important;
}


.product_img {
    box-sizing: border-box;
    height: 360px !important;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.product_img img{
    height: 100%;
}

.brands_id{
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--main-color-2) !important;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    height: 55px;
    display: flex;
    align-items: center;
}


.brand_p{
    font-family: A,sans-serif;
    font-size: 15px;
    color: var(--main-color-1) !important;
}

.brand_ico{
    margin-left: auto;

    opacity: 0.1;
    color:  var(--main-color-1) !important;
}




.brands select{

    appearance: none!important;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding: 10px;
    font-family: A, sans-serif;
    width: 100%;
    height: auto;
    color:  var(--main-color-1) !important;
    background-color: var(--main-color-2) !important;
    border-style: none;


}

.loader {
    position: fixed; /* Фиксированное позиционирование для отображения поверх контента */
    top: 0; /* Расположение вверху экрана */
    left: 0; /* Расположение влево экрана */
    width: 100%; /* Занимать всю ширину экрана */
    height: 100%; /* Занимать всю высоту экрана */
    background-color: rgba(255, 255, 255, 0.4); /* Прозрачный белый фон */
    display: flex; /* Использовать flexbox для выравнивания содержимого */
    justify-content: center; /* Выравнивание по горизонтали */
    align-items: center; /* Выравнивание по вертикали */
    z-index: 999999999999; /* Положить поверх остального контента */
}

.loader_background {
    position: absolute; /* Абсолютное позиционирование внутри .loader */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(6px); /* Применение размытия к заднему фону */
}

.loader img {
    width: 50px; /* Ширина изображения */
    height: 50px; /* Высота изображения */
    position: absolute; /* Абсолютное позиционирование изображения */
    top: 50%; /* Расположение по вертикали в середине */
    left: 50%; /* Расположение по горизонтали в середине */
    transform: translate(-50%, -50%); /* Сдвиг влево и вверх на половину ширины и высоты изображения */
    z-index: 10000; /* Положить поверх остального контента */
}

.load_more{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.load_more button{
    font-family: A,sans-serif;
    color: var(--main-color-2);
    padding: 10px;
    background-color: var(--main-color-5);
    border-radius: 10px;
    border-style: none;
    width: 300px;
    font-size: 15px;
    height: 55px;

}