
.User {
    width: 100%;
    height: 100%;
}

.User_container {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 40vh;
}

.User_container.active {
    display: none;
}


.error-border {
    outline: 3px solid rgba(237, 28, 36, 0.53) !important;

}

.user_img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--main-color-3) !important;
}


.verify {
    flex-direction: column;
    margin: 70px auto;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.verify.active {
    display: none;
}

.vb1 {
    background-color: var(--main-color-1) !important;
    border-color: var(--main-color-2) !important;
    border-radius: 15px;
    border-style: solid;
    border-width: 3px;
    color: var(--main-color-2) !important;
    cursor: pointer;
    font-family: "A", sans-serif;
    font-size: 16px;
    height: 50px;
    width: 100%;
    max-width: 300px;
}

.vb2 {
    margin-top: 15px;
    background-color: var(--main-color-3) !important;
    border-color: var(--main-color-3) !important;
    border-radius: 15px;
    border-style: solid;
    border-width: 3px;
    color: var(--main-color-1) !important;
    cursor: pointer;
    font-family: "A", sans-serif;
    font-size: 16px;
    height: 50px;
    width: 100%;
    max-width: 300px;
}

.vb3 {
    margin-top: 15px;
    background-color: var(--main-color-5) !important;
    border-color: var(--main-color-2) !important;
    border-radius: 15px;
    border-style: solid;
    border-width: 3px;
    color: var(--main-color-2) !important;
    cursor: pointer;
    font-family: "A", sans-serif;
    font-size: 16px;
    height: 50px;
    width: 100%;
    max-width: 300px;
}

.vi {

    background-color: var(--main-color-3) !important;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    color: var(--main-color-1) !important;
    cursor: pointer;
    font-family: "A", sans-serif;
    font-size: 15px;
    font-weight: 500 !important;
    margin-top: 15px;
    max-width: 300px;
    outline: none;
    width: 100%;
    height: 55px;
    padding: 0 60px 0 20px;
}
.ti {

    background-color: var(--main-color-3) !important;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    color: var(--main-color-1) !important;
    cursor: pointer;
    font-family: "A", sans-serif;
    font-size: 15px;
    font-weight: 500 !important;
    margin-top: 15px;
    max-width: 300px;
    outline: none;
    width: 100%;
    height: 150px;
    padding: 15px 60px 0 20px;
}
.YES_icon{
    margin-top: 40px;
    margin-bottom: -50px;
    width: 100%;
    height: 60px;
    display: none;
    justify-content: center;
    align-items: center;

}

.YES_icon.active{
    display: flex!important;
}
.YES_icon svg{
    color: #82cb19;
    width: 100%;
    height: 100%;
}